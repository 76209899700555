import ExportMenu from "@/components/collections/ExportMenu";
import Archie from "@/components/search/Archie";
import SearchResults from "@/components/search/SearchResults";
import useArchieData from "@/hooks/useArchieData";
import useSearchFilters from "@/hooks/useSearchFilters";

import semanticScholarApi, {
  ClassicSearchResponse,
} from "@/lib/SemanticScholarApi";
import { runArchie } from "@/lib/cloudFunctions/runArchie";
import { dbIa } from "@/lib/firebase";
import Container from "@/ui/Container";
import Pagination from "@/ui/Pagination";
import SearchArticle from "@/ui/SearchArticle";
import SearchLoading from "@/ui/pending/SearchLoading";

import { Box, Icon, IconButton } from "@chakra-ui/react";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IoArrowUp } from "react-icons/io5";

import { useNavigate, useNavigation, useSearchParams } from "react-router-dom";

const Search = () => {
  const [params] = useSearchParams();
  const query = params.get("q");
  const token = params.get("token");
  const page = parseInt(params.get("page") || "1", 10);
  const ai_searched = params.get("ai-search") === "true";
  const { isOpenAccess, minCitations, fieldsOfStudy, publicationTypes, year } =
    useSearchFilters();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const { setArchieData, papers } = useArchieData();
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState<ClassicSearchResponse>();
  const { t } = useTranslation();
  const busy =
    navigation.state === "loading" &&
    navigation.location.pathname === "/search";

  const [shouldDisplayScrollToTop, setShouldDisplayScrollToTop] =
    useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page, query, busy]);

  useEffect(() => {
    const displayScrollToTop = () => {
      if (window.scrollY > 100) {
        setShouldDisplayScrollToTop(true);
      } else {
        setShouldDisplayScrollToTop(false);
      }
    };

    window.addEventListener("scroll", displayScrollToTop, { passive: true });

    return () => window.removeEventListener("scroll", displayScrollToTop);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (token && ai_searched) {
      const unsub = onSnapshot(doc(dbIa, "ai_searches", token), (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          if (data.status === "pending") {
            runArchie({
              token,
            });
          }
          if (data.status === "completed" || data.status === undefined) {
            //Data.status is not defined in the snippet for Archie v1 searches
            setArchieData({
              language: data.language,
              papers: data.results,
              token: token,
            });
            setIsLoading(false);
          }
        }
      });

      return () => unsub();
    } else {
      const additionalParams: {
        [key: string]: string;
      } = {};

      if (year) {
        additionalParams.year = `${year.min}-${year.max}`;
      }

      if (isOpenAccess) {
        additionalParams.openAccessPdf = "true";
      }

      if (minCitations) {
        additionalParams.minCitationCount = minCitations.toString();
      }

      if (fieldsOfStudy) {
        additionalParams.fieldsOfStudy = fieldsOfStudy.join(",");
      }
      if (publicationTypes) {
        additionalParams.publicationTypes = publicationTypes.join(",");
      }

      semanticScholarApi.search
        .get("/paper/search", {
          params: {
            query: query,
            offset: (page - 1) * 10,
            limit: 10,
            fields:
              "title,authors,journal,externalIds,tldr,publicationDate,year,citationCount,corpusId,publicationTypes",
            ...additionalParams,
          },
        })
        .then((res) => {
          setResults(res.data);
          setIsLoading(false);
        });
    }
  }, [
    token,
    ai_searched,
    setArchieData,
    query,
    page,
    year,
    isOpenAccess,
    minCitations,
    fieldsOfStudy,
    publicationTypes,
  ]);
  if (!query) {
    navigate("/", { replace: true });
    return null;
  }

  return (
    <Container hasSearch>
      <Box
        mt={8}
        p={[4, 8]}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={4}
        w={"100%"}
        h={"100%"}
        borderRadius={24}
        bgColor={"#F2F6F9"}
      >
        <>
          {busy || isLoading ? (
            <SearchLoading />
          ) : (
            <>
              {ai_searched ? (
                <>
                  {papers.length === 0 ? (
                    <Box>{t("search.error")} </Box>
                  ) : (
                    <>
                      <Archie />
                      <SearchResults />
                    </>
                  )}
                </>
              ) : results && results.total > 0 ? (
                <>
                  <Box gap={8} p={2} display={"flex"} flexDirection={"column"}>
                    <Box alignSelf={"flex-end"}>
                      <ExportMenu
                        papers={results.data}
                        title={query as string}
                      />
                    </Box>
                    {results.data.map((document) => (
                      <SearchArticle key={document.paperId} {...document} />
                    ))}
                  </Box>
                  <Pagination
                    total={results.total > 1000 ? 1000 : results.total}
                    current={page}
                    onChange={(currentPage) => {
                      const queryParams = new URLSearchParams(
                        window.location.search
                      );

                      queryParams.set("page", currentPage.toString());
                      navigate({
                        search: queryParams.toString(),
                      });
                    }}
                    resultsPerPage={10}
                  />
                </>
              ) : (
                <Box>{t("search.error")} </Box>
              )}
              {/*  <Suspense fallback={<SearchLoading />}>
                <Await
                  resolve={results}
                  errorElement={<Text>{t("search.error")}</Text>}
                >
                  {(
                    results: ClassicSearchResponse | AugmentedSearchResponse
                  ) => {
                    if (results.isAiSearch) {
                      return (
                        <>
                          <Archie data={results} />
                          <SearchResults />
                        </>
                      );
                    }

                    return (
                      <>
                        
                    );
                  }}
                </Await>
              </Suspense> */}
            </>
          )}
        </>
      </Box>
      <IconButton
        icon={<Icon as={IoArrowUp} w={6} h={6} />}
        aria-label="Scroll to top"
        onClick={() =>
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          })
        }
        display={shouldDisplayScrollToTop ? "block" : "none"}
        position={"fixed"}
        bottom={8}
        right={8}
      />
    </Container>
  );
};

export default Search;
