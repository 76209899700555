import {
  AccordionPanel,
  Box,
  ListItem,
  SkeletonText,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import ArchieQAnswer from "./ArchieQAnswer";
import { useEffect, useState } from "react";
import { getArchieQAnswer } from "@/lib/cloudFunctions/ArchieQ";
import { useTranslation } from "react-i18next";

type ArchieQAnswerContainerProps = {
  index: number;
  isOpen: boolean;
  answer: (string | JSX.Element)[] | null;
  question: string;
  token: string;
};
const ArchieQAnswerContainer = (props: ArchieQAnswerContainerProps) => {
  const { index, isOpen, answer, question, token } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (answer || !isOpen) {
      setLoading(false);
      return;
    }
    setError(false);
    setLoading(true);
    getArchieQAnswer({
      token,
      question_index: index,
    })
      .then((data) => {
        if (data.data.answer === "N/A") {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [answer, index, isOpen, question, token]);

  if (!token) {
    return null;
  }

  if (error) {
    return (
      <AccordionPanel>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          gap={4}
          marginY={"auto"}
          minW={"400px"}
        >
          <Text> {t("archie.error.title")}</Text>
          <UnorderedList>
            <ListItem>{t("archie.error.0")}</ListItem>
            <ListItem>{t("archie.error.2")}</ListItem>
            <ListItem>{t("archie.error.3")}</ListItem>
          </UnorderedList>

          <Text>{t("archie.error.final")}</Text>
        </Box>
      </AccordionPanel>
    );
  }

  if (loading) {
    return (
      <AccordionPanel>
        <SkeletonText noOfLines={5} spacing={4} skeletonHeight={4} />
      </AccordionPanel>
    );
  }

  return (
    <AccordionPanel>
      <ArchieQAnswer answer={answer} question={question} />
    </AccordionPanel>
  );
};

export default ArchieQAnswerContainer;
