import {
  Card,
  CardBody,
  HStack,
  Icon,
  IconButton,
  LinkBox,
  LinkOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdCollectionsBookmark, MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import AddElementToCollectionModal from "./AddElementToCollectionModal";
import { useCollections } from "@/hooks/useCollections";
import useArchieData from "@/hooks/useArchieData";

type CollectionSearchProps = {
  search: {
    token: string;
    query: string;
  };
  collectionId: string;
};
const CollectionSearch = ({ search, collectionId }: CollectionSearchProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { removeElementFromCollection } = useCollections();
  const reset = useArchieData((state) => state.reset);
  return (
    <>
      <LinkBox
        minW={300}
        w={"100%"}
        key={search.token}
        bgColor={"white"}
        borderRadius={12}
        p={4}
        onClick={reset}
        orientation="horizontal"
        as={Card}
      >
        <CardBody padding={0}>
          <HStack justifyContent={"space-between"}>
            <LinkOverlay
              as={Link}
              to={`/search?q=${search.query}&token=${search.token}&ai-search=true`}
            >
              <Text fontWeight={"bold"}>{search.query}</Text>
            </LinkOverlay>
            <HStack>
              <IconButton
                onClick={() =>
                  removeElementFromCollection(
                    "search",
                    collectionId,
                    search.token
                  )
                }
                variant={"ghost"}
                aria-label="Delete from collection"
                icon={<Icon as={MdDelete} color={"tomato"} w={6} h={6} />}
              />
              <IconButton
                onClick={onOpen}
                variant={"ghost"}
                aria-label="Add to collection"
                icon={<Icon as={MdCollectionsBookmark} w={6} h={6} />}
              />
            </HStack>
          </HStack>
        </CardBody>
      </LinkBox>
      <AddElementToCollectionModal
        isOpen={isOpen}
        onClose={onClose}
        elementId={search.token}
        elementType="search"
      />
    </>
  );
};

export default CollectionSearch;
