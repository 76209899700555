import { useUserSession } from "@/hooks/useUserSession";
import { authIa } from "@/lib/firebase";
import AuthContainer from "@/ui/Auth/AuthContainer";
import {
  Box,
  Button,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from "@chakra-ui/react";
import { signOut, updatePassword } from "firebase/auth";
import { updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

type FormData = {
  password: string;
};

const PasswordReset = () => {
  const { t } = useTranslation();
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const navigate = useNavigate();

  const { user } = useUserSession();
  const toast = useToast();

  useEffect(() => {
    if (!user?.shouldResetPassword) {
      navigate("/", { replace: true });
    }
  }, [user, navigate]);
  const onSubmit = handleSubmit(async (data) => {
    try {
      setSubmitError(null);
      await updatePassword(authIa.currentUser!, data.password);
      await updateDoc(user!.ref, {
        shouldResetPassword: false,
      });
      toast({
        title: t("auth.passwordReset.success"),
        status: "success",
        position: "top",
      });
    } catch (error) {
      console.error(error);

      if (error === "auth/user-not-found") {
        setSubmitError(t("auth.login.notFound"));
      }
      setSubmitError(t("auth.passwordReset.error"));
    }
  });

  return (
    <AuthContainer>
      <Heading
        mb={8}
        color={"#fff"}
        textAlign={"center"}
        sx={{
          textWrap: "balance",
        }}
      >
        {t("auth.passwordReset.title")}
      </Heading>
      <Text
        sx={{
          textWrap: "balance",
        }}
        fontWeight={"bold"}
        color={"#fff"}
        textAlign={"center"}
      >
        {t("auth.passwordReset.description")}
      </Text>

      {submitError && <Box color={"red"}>{submitError}</Box>}
      <Box
        as="form"
        display={"flex"}
        flexDir={"column"}
        gap={8}
        w={"clamp(280px, 70%, 500px)"}
        onSubmit={onSubmit}
      >
        <InputGroup>
          <Input
            variant={"auth"}
            placeholder={t("auth.login.password")}
            {...register("password")}
            required
            type={isPasswordHidden ? "password" : "text"}
          />
          <InputRightElement>
            <IconButton
              variant={"ghost"}
              icon={
                <Icon as={isPasswordHidden ? IoEye : IoEyeOff} w={5} h={5} />
              }
              onClick={() => setIsPasswordHidden(!isPasswordHidden)}
              aria-label={t("auth.login.togglePasswordVisibility")}
            />
          </InputRightElement>
        </InputGroup>
        {errors.password && <Box color={"red"}>{errors.password.message}</Box>}
        <Button colorScheme="brand" type="submit" isLoading={isSubmitting}>
          {t("auth.passwordReset.submit")}
        </Button>
        <Button onClick={() => signOut(authIa)} variant={"text"}>
          {t("auth.passwordReset.cancel")}
        </Button>
      </Box>
    </AuthContainer>
  );
};

export default PasswordReset;
