import { useUserSession } from "@/hooks/useUserSession";
import { authIa, dbIa } from "@/lib/firebase";

import {
  Box,
  Button,
  Checkbox,
  Grid,
  GridItem,
  Heading,
  Input,
  VStack,
  Wrap,
  useToast,
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";

import { collection, getDocs, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import countries from "i18n-iso-countries";
import countriesEn from "i18n-iso-countries/langs/en.json";
import countriesFr from "i18n-iso-countries/langs/fr.json";
import { signInWithEmailAndPassword, updatePassword } from "firebase/auth";
import { FirebaseError } from "firebase/app";
countries.registerLocale(countriesEn);
countries.registerLocale(countriesFr);
type Item = {
  label?: string;
  value: string;
};

type Domain = {
  label: string;
  value: string;
  isPro: boolean;
};

type TFormData = {
  firstName: string;
  lastName: string;
  domain: string | null;
  zip: string;

  newsletter: boolean;
  specialty: string | null;
  country: string;
  status: string;
  rpps: string | null;
};

type PasswordFormData = {
  password: string;
  newPassword: string;
};
const Settings = () => {
  const { t, i18n } = useTranslation();
  const { user } = useUserSession();
  const [specialties, setSpecialties] = useState<Item[]>([]);
  const [statuses, setStatuses] = useState<Item[]>([]);
  const [domains, setDomains] = useState<Domain[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const hasPasswordProvider = user?.providerData?.some(
    (provider: { providerId: string }) => provider.providerId === "password"
  );

  const toast = useToast();

  const {
    register: registerInfo,
    handleSubmit: handleSubmitInfo,
    watch: watchInfo,

    resetField: resetFieldInfo,
    setValue: setValueInfo,

    formState: {
      isDirty: isDirtyInfo,
      isSubmitting: isSubmittingInfo,
      defaultValues: defaultValuesInfo,
    },
  } = useForm<TFormData>({
    values: {
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      status: user?.status ?? "",
      domain: user?.domain ?? null,
      zip: user?.zip ?? "",
      newsletter: user?.newsletter ?? false,
      country: user?.country ?? "",
      specialty: user?.specialty ?? null,
      rpps: user?.rpps ?? null,
    },
  });

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    reset: resetPassword,
    formState: { isDirty: isDirtyPassword, isSubmitting: isSubmittingPassword },
  } = useForm<PasswordFormData>({
    defaultValues: {
      password: "",
      newPassword: "",
    },
  });

  const status = watchInfo("status");
  const domain = watchInfo("domain");
  const specialty = watchInfo("specialty");

  const newsletter = watchInfo("newsletter");
  const onSubmitInfos = handleSubmitInfo(async (data) => {
    if (!user) return;
    await updateDoc(user?.ref, {
      ...data,
    });
    //Using RHF reset method would display the keys in the autocomplete fields
    Object.entries(data).forEach(([key, value]) => {
      resetFieldInfo(key as keyof TFormData, {
        defaultValue: value,
      });
    });

    toast({
      title: t("profile.settings.saved"),
      status: "success",
    });
  });
  const _countries = countries.getNames(i18n.language);
  const country = watchInfo("country");
  useEffect(() => {
    (async () => {
      const [specialties, statuses, domains] = await Promise.all([
        getDocs(collection(dbIa, "specialties")),
        getDocs(collection(dbIa, "users_statuses")),
        getDocs(collection(dbIa, "users_domains")),
      ]);

      const _specialties = specialties.docs.map((doc) => ({
        label: doc.data()[i18n.language],
        value: doc.id,
      }));
      setSpecialties(_specialties);

      const _statuses = statuses.docs.map((doc) => ({
        label: doc.data()[i18n.language],
        value: doc.id,
      }));
      setStatuses(_statuses);

      const _domains = domains.docs.map((doc) => ({
        label: doc.data()[i18n.language],
        value: doc.id,
        isPro: doc.data().isProOnly,
      }));
      setDomains(_domains);
    })();
  }, [i18n.language]);

  const handlePasswordReset = handleSubmitPassword(async (data) => {
    if (!user) return;

    try {
      const _user = await signInWithEmailAndPassword(
        authIa,
        user?.id,
        data.password
      );
      updatePassword(_user.user, data.newPassword);
      toast({
        title: t("profile.settings.passwordChanged"),
        status: "success",
      });
      resetPassword();
    } catch (error) {
      if (
        error instanceof FirebaseError &&
        error.code === "auth/wrong-password"
      ) {
        toast({
          title: t("profile.settings.wrongPassword"),
          status: "error",
        });
      } else {
        toast({
          title: t("profile.settings.error"),
          status: "error",
        });
      }
    }
  });

  return (
    <Box
      flex={1}
      flexWrap={"wrap"}
      borderRadius={32}
      paddingX={[4, 4, 12]}
      paddingY={[2, 4]}
      bgColor={"white"}
    >
      <Heading textAlign={"center"}>{t("profile.menu.settings")}</Heading>

      <Heading as={"h3"} size={"md"} my={4}>
        {t("profile.settings.infos")}
      </Heading>
      <Grid
        templateColumns={"1fr 1fr"}
        gap={4}
        as={"form"}
        onSubmit={onSubmitInfos}
      >
        <GridItem colSpan={2}>
          <Input
            placeholder={t("profile.settings.email")}
            value={user?.email}
            isDisabled
          />
        </GridItem>
        <GridItem colSpan={[2, 1]}>
          <Input
            placeholder={t("profile.settings.firstname")}
            {...registerInfo("firstName")}
            required
          />
        </GridItem>
        <GridItem colSpan={[2, 2, 2, 1]}>
          <Input
            placeholder={t("profile.settings.lastname")}
            {...registerInfo("lastName")}
            required
          />
        </GridItem>
        <GridItem colSpan={[2, 1]}>
          <AutoComplete
            openOnFocus
            onSelectOption={({ item }: { item: Item }) => {
              setValueInfo("status", item.value);
              setSelectedStatus(item.value);
            }}
            value={statuses.find((item) => item.value === status)?.label}
          >
            <AutoCompleteInput
              placeholder={t("auth.register.status")}
              {...registerInfo("status")}
              required
            />
            <AutoCompleteList>
              {statuses.map((status) => (
                <AutoCompleteItem
                  color="#000"
                  key={status.value}
                  value={status.value}
                  label={status.label}
                >
                  {status.label}
                </AutoCompleteItem>
              ))}
            </AutoCompleteList>
          </AutoComplete>
        </GridItem>
        {status && status !== "patient" && (
          <>
            <GridItem colSpan={[2, 1]}>
              <AutoComplete
                openOnFocus
                onSelectOption={({ item }: { item: Item }) => {
                  setValueInfo("domain", item.value);
                }}
                value={domains.find((item) => item.value === domain)?.label}
              >
                <AutoCompleteInput
                  placeholder={t("auth.register.domain")}
                  required={status !== "patient"}
                  {...registerInfo("domain")}
                />
                <AutoCompleteList>
                  {domains
                    .filter((e) => status != "student" || !e.isPro)
                    .map((status) => (
                      <AutoCompleteItem
                        color="#000"
                        key={status.value}
                        value={status.value}
                        label={status.label}
                      >
                        {status.label}
                      </AutoCompleteItem>
                    ))}
                </AutoCompleteList>
              </AutoComplete>
            </GridItem>
            <GridItem colSpan={[2, 1]}>
              <AutoComplete
                openOnFocus
                onSelectOption={({ item }: { item: Item }) => {
                  setValueInfo("specialty", item.value);
                }}
                value={
                  specialties.find((item) => item.value === specialty)?.label
                }
              >
                <AutoCompleteInput
                  placeholder={t("auth.register.specialty")}
                  required={status !== "patient"}
                  {...registerInfo("specialty")}
                />
                <AutoCompleteList>
                  {specialties.map((spe) => (
                    <AutoCompleteItem
                      color="#000"
                      key={spe.value}
                      value={spe.value}
                    >
                      {spe.label}
                    </AutoCompleteItem>
                  ))}
                </AutoCompleteList>
              </AutoComplete>
            </GridItem>
          </>
        )}

        <GridItem colSpan={[2, 1]}>
          <Input
            placeholder={t("auth.register.zip")}
            {...registerInfo("zip")}
            required
          />
        </GridItem>
        <GridItem colSpan={[2, 1]}>
          <AutoComplete
            openOnFocus
            onSelectOption={({ item }: { item: Item }) => {
              setValueInfo("country", item.value);
            }}
            value={_countries[country]}
          >
            <AutoCompleteInput
              placeholder={t("auth.register.country")}
              {...registerInfo("country")}
              required
            />
            <AutoCompleteList>
              {Object.entries(_countries).map(([code, country]) => (
                <AutoCompleteItem
                  color="#000"
                  key={country}
                  value={code}
                  label={country}
                >
                  {country}
                </AutoCompleteItem>
              ))}
            </AutoCompleteList>
          </AutoComplete>
        </GridItem>
        {status && selectedStatus !== "patient" && (
          <GridItem colSpan={[2, 1]}>
            <Input
              placeholder={t("auth.register.rpps")}
              {...registerInfo("rpps")}
            />
          </GridItem>
        )}
        <GridItem colSpan={2}>
          <Checkbox
            colorScheme="brand"
            {...registerInfo("newsletter")}
            isChecked={newsletter}
          >
            {t("auth.register.newsletter")}
          </Checkbox>
        </GridItem>
        {isDirtyInfo && (
          <GridItem
            colSpan={2}
            display={"flex"}
            gap={4}
            alignItems={"center"}
            justifyContent={"center"}
            flexWrap={"wrap"}
          >
            <Button
              type="reset"
              isLoading={isSubmittingInfo}
              onClick={(e) => {
                e.preventDefault();

                //Using RHF reset method would display the keys in the autocomplete fields
                Object.keys(defaultValuesInfo as object).forEach((key) => {
                  resetFieldInfo(key as keyof TFormData);
                });
              }}
            >
              {t("profile.settings.reset")}
            </Button>

            <Button
              type="submit"
              colorScheme="brand"
              isLoading={isSubmittingInfo}
            >
              {t("profile.settings.save")}
            </Button>
          </GridItem>
        )}
      </Grid>
      <VStack as="form" onSubmit={handlePasswordReset} spacing={4}>
        {hasPasswordProvider && (
          <Grid width={"100%"} gap={15}>
            <Heading as={"h3"} size={"md"} my={4} marginBottom={0}>
              {t("profile.settings.security")}
            </Heading>
            <Input
              type="password"
              placeholder={t("settings.password")}
              {...registerPassword("password")}
              required
            />
            <Input
              type="password"
              placeholder={t("settings.passwordConfirm")}
              {...registerPassword("newPassword")}
              required
            />
          </Grid>
        )}
        {isDirtyPassword && (
          <Wrap>
            <Button
              type="reset"
              isLoading={isSubmittingPassword}
              onClick={(e) => {
                e.preventDefault();
                resetPassword();
              }}
            >
              {t("profile.settings.reset")}
            </Button>

            <Button
              type="submit"
              colorScheme="brand"
              isLoading={isSubmittingPassword}
            >
              {t("profile.settings.changePassword")}
            </Button>
          </Wrap>
        )}
      </VStack>
      <VStack></VStack>
    </Box>
  );
};

export default Settings;
