import { functions } from "@/lib/firebase";
import { httpsCallable } from "firebase/functions";

type SearchVenuesRequest = {
  query: string;
};

type SearchVenuesResponse = {
  venues: Array<{
    id: string;
    name: string;
  }>;
};

const searchVenues = httpsCallable<SearchVenuesRequest, SearchVenuesResponse>(
  functions,
  "search_venues"
);

export { searchVenues };
