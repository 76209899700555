import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag,
  Item,
  ItemTag,
} from "@choc-ui/chakra-autocomplete";
import classes from "./VenuesAutocomplete.module.css";
import { useEffect, useState } from "react";
import { searchVenues } from "@/lib/cloudFunctions/searchVenues";
import { Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
type VenuesAutocompleteProps = {
  placeholder?: string;
  values: Item[];
  onChange: (value: Item) => void;
  onTagRemove: (tag: ItemTag) => void;
  id: string;
};
const VenuesAutocomplete = (props: VenuesAutocompleteProps) => {
  const { placeholder, id, onTagRemove, onChange, values } = props;
  const [options, setOptions] = useState<Item[]>([]);
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  useEffect(() => {
    if (value) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        searchVenues({ query: value }).then((response) => {
          setOptions(
            response.data.venues.map((venue) => ({
              value: venue.id,
              label: venue.name,
            }))
          );

          setIsLoading(false);
        });
      }, 300);

      return () => clearTimeout(timer);
    } else {
      setIsLoading(false);
    }
  }, [value, setOptions, setIsLoading]);

  return (
    <AutoComplete
      id={id}
      multiple
      values={values}
      onSelectOption={(value) => {
        onChange({
          value: value.item.value,
          label: options.find((option) => option.value === value.item.value)
            ?.label,
        });
      }}
      isLoading={isLoading}
      openOnFocus
      disableFilter
      emptyState={
        <Text textAlign="center" mx={2} fontStyle={"italic"} color="gray.500">
          {value
            ? t("search.filters.noResults")
            : t("search.filters.startTyping")}
        </Text>
      }
    >
      <AutoCompleteInput
        placeholder={placeholder}
        className={classes.input}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        loadingIcon={<Spinner size="xs" />}
      >
        {({ tags }) =>
          tags.map((tag, tid) => (
            <AutoCompleteTag
              key={tid}
              label={tag.label.label}
              onRemove={() => onTagRemove(tag)}
            />
          ))
        }
      </AutoCompleteInput>
      <AutoCompleteList>
        {options.map((option, oid) => (
          <AutoCompleteItem
            key={`option-${oid}`}
            value={option.value}
            textTransform="capitalize"
          >
            {option.label || option.value}
          </AutoCompleteItem>
        ))}
      </AutoCompleteList>
    </AutoComplete>
  );
};

export default VenuesAutocomplete;
