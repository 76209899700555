import Container from "@/ui/Container";
import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const Synthia = () => {
  const { t } = useTranslation();

  const listItems = [
    t("synthia.steps.0"),
    t("synthia.steps.1"),
    t("synthia.steps.2"),
  ];

  return (
    <Container hasSearch>
      <Box
        bgImage={"/backgrounds/logo-synthia.svg"}
        bgSize={"cover"}
        color={"#000"}
        mt={8}
        p={[4, 8]}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        flex={1}
        borderRadius={24}
        gap={6}
      >
        <Text mb={4} fontSize={"1.5rem"} fontWeight={700}>
          {t("synthia.available")}
        </Text>

        <Heading
          as={"h1"}
          size={"xl"}
          alignItems={"center"}
          marginBottom={"1.5rem"}
        >
          <Text
            as={"span"}
            mb={4}
            color={"#fff"}
            textAlign={"center"}
            bgColor={"rgb(33, 35, 26)"}
            paddingRight={3}
            paddingLeft={3}
            borderRadius={50}
            marginBottom={-1}
            height={"fit-content"}
          >
            {t("synthia.name")}
          </Text>
          {t("synthia.title")}
        </Heading>

        <Text mb={4} fontSize={"1.5rem"} marginBottom={"1.5rem"}>
          {t("synthia.subtitle.0")}
          <Text as={"span"} fontWeight={700}>
            {t("synthia.name")}
          </Text>
          {t("synthia.subtitle.1")}
        </Text>
        <Stack direction={"column"} marginBottom={"1.5rem"}>
          {listItems.map((item, index) => (
            <Text
              key={index}
              fontSize={"1.5rem"}
              width={"fit-content"}
              paddingRight={3}
              borderRadius={5}
              margin={0}
            >
              {`${index + 1}. ${item}`}
            </Text>
          ))}
        </Stack>
        <Text mb={4} fontSize={"1.5rem"} margin={0}>
          <Text as={"span"} fontWeight={700}>
            {t("synthia.name")}
          </Text>
          {t("synthia.resume")}
        </Text>
        <Text mb={4} fontWeight={700} fontSize={"1.5rem"} marginTop={"1.5rem"}>
          {t("synthia.stayConnect")}
        </Text>
      </Box>
    </Container>
  );
};

export default Synthia;
