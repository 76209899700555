import AuthProviders from "@/components/AuthProviders";
import { useUserSession } from "@/hooks/useUserSession";
import { authIa } from "@/lib/firebase";
import AuthContainer from "@/ui/Auth/AuthContainer";
import {
  Box,
  Button,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";

type FormData = {
  email: string;
  password: string;
};

const Login = () => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user } = useUserSession();
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const { setHasSignedIn } = useUserSession();

  useEffect(() => {
    if (user) {
      if (user.shouldResetPassword) {
        navigate("/reset-password");
      } else if (state && state.from) {
        navigate(state.from, { replace: true });
      } else {
        navigate("/", { replace: true });
      }
    }
  }, [user, navigate, state]);
  const onSubmit = handleSubmit(async (data) => {
    try {
      setSubmitError(null);
      setHasSignedIn(true);
      await signInWithEmailAndPassword(authIa, data.email, data.password);
    } catch (error) {
      console.error(error);
      setHasSignedIn(false);
      if (error === "auth/user-not-found") {
        setSubmitError(t("auth.login.notFound"));
      }
      setSubmitError(t("auth.login.error"));
    }
  });

  return (
    <AuthContainer>
      <Heading mb={8} color={"#fff"}>
        {t("auth.login.title")}
      </Heading>
      {submitError && <Box color={"red"}>{submitError}</Box>}
      <Box
        as="form"
        display={"flex"}
        flexDir={"column"}
        gap={5}
        w={"clamp(280px, 70%, 500px)"}
        onSubmit={onSubmit}
      >
        <Input
          variant={"auth"}
          placeholder={t("auth.login.email")}
          {...register("email")}
          required
        />
        {errors.email && <Box color={"red"}>{errors.email.message}</Box>}
        <InputGroup>
          <Input
            variant={"auth"}
            placeholder={t("auth.login.password")}
            {...register("password")}
            required
            type={isPasswordHidden ? "password" : "text"}
          />
          <InputRightElement>
            <IconButton
              variant={"ghost"}
              icon={
                <Icon as={isPasswordHidden ? IoEye : IoEyeOff} w={5} h={5} />
              }
              onClick={() => setIsPasswordHidden(!isPasswordHidden)}
              aria-label={t("auth.login.togglePasswordVisibility")}
            />
          </InputRightElement>
        </InputGroup>
        {errors.password && <Box color={"red"}>{errors.password.message}</Box>}
        <Button
          colorScheme="brand"
          type="submit"
          isLoading={isSubmitting || loading}
        >
          {t("auth.login.submit")}
        </Button>
        <AuthProviders loading={loading} setLoading={setLoading} />
        <Stack alignSelf={"center"} spacing={0}>
          <Text textAlign={"center"} color={"#fff"}>
            {t("auth.login.noAccount")}
          </Text>
          <Text
            color={"#fff"}
            as={Link}
            to="/register"
            textAlign={"center"}
            textDecoration={"underline"}
            state={{ from: state?.from || "/" }}
          >
            {t("auth.login.register")}
          </Text>
          <Text
            mt={4}
            color={"#fff"}
            as={Link}
            to="/forgot-password"
            textAlign={"center"}
            textDecoration={"underline"}
          >
            {t("auth.login.passwordForgotten")}
          </Text>
        </Stack>
      </Box>
    </AuthContainer>
  );
};

export default Login;
