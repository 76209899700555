import { functions } from "@/lib/firebase";
import { httpsCallable } from "firebase/functions";

type ArchieQQuestionRequest = {
  token: string;
};

type ArchieQQuestionResponse = {
  type: "questions";
  questions: Array<string>;
};

type ArchieQAnswerResponse = {
  type: "response";
  answer: string;
  papers: Array<string>;
};

type ArchieQAnswerRequest = {
  token: string;
  question_index: number;
};

const getArchieQQuestions = httpsCallable<
  ArchieQQuestionRequest,
  ArchieQQuestionResponse
>(functions, "get_archie_q_questions");

const getArchieQAnswer = httpsCallable<
  ArchieQAnswerRequest,
  ArchieQAnswerResponse
>(functions, "get_archie_q_answer");

export { getArchieQQuestions, getArchieQAnswer };
