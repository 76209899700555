import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Heading, Image, List, ListItem } from "@chakra-ui/react";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box
      as="footer"
      display={"flex"}
      justifyContent={["flex-start", "space-evenly"]}
      alignItems={"flex-start"}
      padding={8}
      gap={8}
      flexWrap={"wrap"}
      color={"#fff"}
      bgColor={"brand.500"}
      w={"100%"}
    >
      <Box as="a" href="https://paperdoc-app.com" alignSelf={"center"}>
        <Image src="/images/logo-full-white.svg" alt="logo" width={200} />
      </Box>
      <Box as="nav">
        <Heading textDecoration={"underline"} as={"h2"} size={"md"} mb={4}>
          {t("footer.services.title")}
        </Heading>
        <List styleType={"none"} spacing={0.4}>
          <ListItem>
            <Link to={"/search"}>{t("sidebar.search")}</Link>
          </ListItem>
          <ListItem>
            <Link to={"https://paperdoc-app.com/"}>
              {t("sidebar.dashboard")}
            </Link>
          </ListItem>
          <ListItem>
            <Link to={"/ia"}>{t("sidebar.ia")}</Link>
          </ListItem>
          <ListItem>
            <Link to={"https://paperdoc-app.com/collections"}>
              {t("footer.services.collections.title")}
            </Link>
          </ListItem>
          <ListItem>
            <Link to={"/toolbox"}>{t("sidebar.toolbox")}</Link>
          </ListItem>
          {/*  <ListItem>
            <Link to={"/storage"}>{t("sidebar.storage")}</Link>
          </ListItem> */}
        </List>
      </Box>
      <Box as="nav">
        <Heading textDecoration={"underline"} as={"h2"} size={"md"} mb={4}>
          {t("footer.about.title")}
        </Heading>
        <List spacing={0.4} styleType={"none"}>
          <ListItem>
            <Link to={"https://paperdoc-app.com"}>
              {t("footer.about.website")}
            </Link>
          </ListItem>
          <ListItem>
            <Link to={"https://paperdoc-app.com/blog"}>
              {t("footer.about.blog")}
            </Link>
          </ListItem>
          <ListItem>
            <Link to={"/help"}>{t("footer.about.help")}</Link>
          </ListItem>
          <ListItem>
            <Link to={"/pricing"}>{t("footer.about.pricing")}</Link>
          </ListItem>
          <ListItem>
            <Link to={"https://paperdoc-app.com/about"}>
              {t("footer.about.who")}
            </Link>
          </ListItem>
          <ListItem>
            <Link to={"/contact"}>{t("footer.about.contact")}</Link>
          </ListItem>
          <ListItem>
            <Link to={"https://paperdoc-app.com/privacy-policy"}>
              {t("footer.about.terms")}
            </Link>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default Footer;
