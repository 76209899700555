import { RefObject } from "react";
import { useUserSession } from "./useUserSession";
import useSearchFilters from "./useSearchFilters";
import {
  addDoc,
  collection,
  DocumentReference,
  increment,
  updateDoc,
} from "firebase/firestore";
import { useSubmit } from "react-router-dom";
import useArchieData from "./useArchieData";
import { usePaperCoinsDisclaimer } from "./usePaperCoinsDisclaimer";
import { dbIa } from "@/lib/firebase";

type SearchData = {
  query: string;
  user: DocumentReference | null;
  createdAt: Date;
  selected_fields_of_study?: string[];
  selected_publication_types?: string[];
  authors?: string[];
  publication_venues?: string[];
  year_range?: [number, number] | null;
  min_citations?: number | null;
  is_open_access?: boolean;
  status: "pending";
};

const useSearchSubmit = (formRef: RefObject<HTMLFormElement>) => {
  const { user } = useUserSession();
  const { openDisclaimer } = usePaperCoinsDisclaimer();
  const {
    fieldsOfStudy,
    year,
    authors,
    minCitations,
    isOpenAccess,
    publicationTypes,
    publicationVenues,
  } = useSearchFilters();
  const { displayed, reset } = useArchieData((state) => ({
    displayed: state.displayed,
    reset: state.reset,
  }));
  const submit = useSubmit();

  const handleSubmit = async (isAiRedirect?: boolean) => {
    const values = new FormData(formRef.current as HTMLFormElement);
    const usedValues: [string, string][] = [];
    let isIaSearch = false;

    if (user && user.currentPlan !== "premium" && user.paperCoins < 1) {
      openDisclaimer("search");
      return;
    }

    if (!values.get("q") || values.get("q")?.toString().trim() === "") {
      return;
    }

    const searchData: SearchData = {
      query: values.get("q")?.toString() || "",
      user: user?.ref || null,
      createdAt: new Date(),
      status: "pending",
    };

    values.forEach((value, key) => {
      if (value && !["ai-search", "insights", "summary"].includes(key)) {
        usedValues.push([key, value.toString()]);
      }
    });

    if (displayed.aiSearch) {
      usedValues.push(["ai-search", "true"]);
      isIaSearch = true;
    }

    if (isAiRedirect) {
      usedValues.push(["ai-search", "true"]);
      isIaSearch = true;
    }

    if (fieldsOfStudy && fieldsOfStudy.length > 0) {
      usedValues.push(["fieldsOfStudy", fieldsOfStudy.join(",")]);
      searchData.selected_fields_of_study = fieldsOfStudy;
    }

    if (publicationTypes && publicationTypes.length > 0) {
      usedValues.push(["publicationTypes", publicationTypes.join(",")]);
      searchData.selected_publication_types = publicationTypes;
    }

    if (authors && authors.length > 0) {
      usedValues.push(["authors", authors.join(",")]);
      searchData.authors = authors;
    }

    if (publicationVenues && publicationVenues.length > 0) {
      usedValues.push([
        "publicationVenues",
        publicationVenues.map((venue) => venue.value).join(","),
      ]);
      searchData.publication_venues = publicationVenues.map(
        (venue) => venue.value
      );
    }

    if (year) {
      usedValues.push(["min_year", year.min]);
      usedValues.push(["max_year", year.max]);
      searchData.year_range = [parseInt(year.min), parseInt(year.max)];
    }

    if (minCitations) {
      usedValues.push(["minCitations", minCitations.toString()]);
      searchData.min_citations = minCitations;
    }

    if (isOpenAccess) {
      usedValues.push(["isOpenAccess", isOpenAccess.toString()]);
      searchData.is_open_access = isOpenAccess;
    }

    if (user) {
      updateDoc(user.ref, {
        searchesCount: increment(1),
      });
    }
    if (isIaSearch) {
      const searchRef = await addDoc(
        collection(dbIa, "ai_searches"),
        searchData
      );
      usedValues.push(["token", searchRef.id]);
      reset();
    }
    const searchParams = new URLSearchParams(usedValues);

    submit(searchParams, {
      action: "/search",
      method: "get",
    });
  };

  return handleSubmit;
};

export default useSearchSubmit;
