import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Heading,
  Hide,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Show,
  Text,
  Link as ChakraLink,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Icon,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useMemo } from "react";

import useArchieData from "@/hooks/useArchieData";

import ArchieSumm from "./Archie/ArchieSUMM";
import ArchieYO from "./Archie/ArchieYO";
import ArchieQ from "./Archie/ArchieQ";
import { useTranslation } from "react-i18next";

import { Link, useSearchParams } from "react-router-dom";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  MdCollectionsBookmark,
  MdOutlineCollectionsBookmark,
} from "react-icons/md";
import { useCollections } from "@/hooks/useCollections";
import AddElementToCollectionModal from "../collections/AddElementToCollectionModal";
import ExportMenu from "../collections/ExportMenu";
import { IoHelpCircleOutline } from "react-icons/io5";

const Archie = () => {
  const { displayed, token, refs, papers } = useArchieData((state) => state);
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const { collections } = useCollections();
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();

  const exportPapers = useMemo(() => {
    const _papers = [...refs].flatMap((corpusId) => {
      const paper = papers.find(
        (paper) => paper.corpusId === parseInt(corpusId)
      );
      if (!paper) return [];
      return paper;
    });

    return _papers;
  }, [refs, papers]);

  return displayed.aiSearch ? (
    <Box display={"flex"} flexDirection={"column"} w={"100%"} gap={8} mb={8}>
      <HStack
        flexWrap={"wrap"}
        gap={4}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Button
          isDisabled={!token}
          mt={-1}
          onClick={openModal}
          rightIcon={
            <Icon
              as={
                collections.find((c) => c.searches.includes(token))
                  ? MdCollectionsBookmark
                  : MdOutlineCollectionsBookmark
              }
              w={6}
              h={6}
            />
          }
        >
          {t("archie.save")}
        </Button>
        <ExportMenu
          papers={exportPapers}
          title={params.get("q") as string}
          disabled={exportPapers.length === 0}
        />
      </HStack>

      {displayed.insights && (
        <Accordion
          defaultIndex={0}
          as={Card}
          bgColor={"#fff"}
          borderRadius={24}
          borderWidth={0}
          allowToggle
        >
          <AccordionItem borderWidth={0} borderColor={"transparent"}>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  as={CardHeader}
                  borderTopRadius={24}
                  borderBottomRadius={isExpanded ? 0 : 24}
                  px={[4, 8]}
                  py={4}
                >
                  <HStack gap={4}>
                    {isExpanded ? (
                      <MinusIcon fontSize={"lg"} />
                    ) : (
                      <AddIcon fontSize={"lg"} />
                    )}
                    <Heading as={"h3"} size={"md"}>
                      Archie
                    </Heading>
                    <Popover trigger="hover">
                      <PopoverTrigger>
                        <Icon
                          as={IoHelpCircleOutline}
                          w={6}
                          h={6}
                          color={"gray"}
                          ml={-2}
                        />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>
                          <Text fontWeight={"bold"}>{t("archie.title")}</Text>
                          <Text>{t("archie.description")}</Text>
                        </PopoverBody>
                        <PopoverFooter>
                          <ChakraLink
                            as={Link}
                            color={"brand.500"}
                            textDecoration={"underline"}
                            to="/help"
                          >
                            {t("archie.learnMore")}
                          </ChakraLink>
                        </PopoverFooter>
                      </PopoverContent>
                    </Popover>
                  </HStack>
                </AccordionButton>
                <AccordionPanel as={CardBody} px={[4, 8]}>
                  <Box
                    display={"flex"}
                    flexDirection={[
                      "column-reverse",
                      "column-reverse",
                      "column-reverse",
                      "column-reverse",
                      "row",
                    ]}
                    gap={6}
                    alignItems={"flex-start"}
                  >
                    <Box flexGrow={1}>
                      <ArchieQ />
                    </Box>

                    <Hide below={"xl"}>
                      <Divider orientation={"vertical"} />
                    </Hide>
                    <Show below={"xl"}>
                      <Divider orientation="horizontal" />
                    </Show>
                    <Box maxW={["100%", "100%", "100%", "100%", "400px"]}>
                      <ArchieYO />
                    </Box>
                  </Box>
                  <ArchieSumm />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      )}

      <AddElementToCollectionModal
        isOpen={isModalOpen}
        onClose={closeModal}
        elementId={token}
        elementType="search"
      />
    </Box>
  ) : null;
};

export default Archie;
