import { useUserSession } from "@/hooks/useUserSession";
import { authIa, functions } from "@/lib/firebase";

import Container from "@/ui/Container";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { signOut } from "firebase/auth";
import { httpsCallable } from "firebase/functions";

import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation } from "react-router-dom";

const Profile = () => {
  const { t } = useTranslation();
  const { user } = useUserSession();
  const location = useLocation();

  const openStripeSession = async () => {
    if (!user) return;
    const createCustomerSession = httpsCallable<
      {
        customerId: string;
        baseUrl: string;
      },
      string
    >(functions, "stripe-createCustomerSession");
    const { data } = await createCustomerSession({
      customerId: user.stripeCustomerId as string,
      baseUrl: window.location.origin,
    });

    window.location.href = data;
  };

  return (
    <Container px={[4, 8]}>
      <Box
        bgImage={"/backgrounds/logo-brand.svg"}
        bgPosition={"center"}
        bgSize={"cover"}
        borderRadius={32}
        paddingX={[4, 4, 12, 32]}
        paddingY={[2, 4]}
        color={"white"}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={[4, 4, 12, 32]}
        marginBottom={[8, 12]}
      >
        <VStack alignItems={"center"} spacing={2}>
          <Heading
            sx={{
              textWrap: "balance",
            }}
            textAlign={"center"}
          >
            {t("profile.title", { name: user?.firstName || "" })}
          </Heading>
          <Text
            sx={{
              textWrap: "balance",
            }}
            textAlign={"center"}
          >
            {t("profile.subtitle")}
          </Text>
        </VStack>
      </Box>
      <Stack
        direction={["column", "column", "row"]}
        alignItems={"flex-start"}
        gap={[8, 12]}
        position={"relative"}
      >
        <Box
          paddingY={[4, 4, 8]}
          borderRadius={32}
          bgColor={"white"}
          position={"sticky"}
          zIndex={10}
          top={"100px"}
          display={"flex"}
          flexDirection={["row", "column"]}
          gap={[4, 4, 12]}
          flexWrap={"wrap"}
          w={"100%"}
          maxW={["none", "none", "300px"]}
        >
          <Image
            src={"/images/stats.svg"}
            w={"100%"}
            hideBelow={"md"}
            aspectRatio={1}
          />
          <Stack
            direction={["row", "row", "column"]}
            spacing={4}
            paddingX={[4, 4, 8]}
            alignItems={"stretch"}
            justifyContent={"center"}
            w={"100%"}
            hideBelow={"md"}
          >
            <Button
              as={Link}
              to={"/profile"}
              colorScheme={location.pathname === "/profile" ? "brand" : "gray"}
              borderRadius={8}
              flexShrink={0}
            >
              {t("profile.menu.recap")}
            </Button>
            <Button
              as={Link}
              to={"/profile/history"}
              colorScheme={
                location.pathname === "/profile/history" ? "brand" : "gray"
              }
              borderRadius={8}
              flexShrink={0}
            >
              {t("profile.menu.history")}
            </Button>
            <Button
              as={Link}
              to={"/profile/summaries"}
              colorScheme={
                location.pathname === "/profile/summaries" ? "brand" : "gray"
              }
              borderRadius={8}
              flexShrink={0}
            >
              {t("profile.menu.summaries")}
            </Button>
            <Button
              as={Link}
              to={"/profile/settings"}
              colorScheme={
                location.pathname === "/profile/settings" ? "brand" : "gray"
              }
              borderRadius={8}
              flexShrink={0}
            >
              {t("profile.menu.settings")}
            </Button>
            {user?.currentPlan ? (
              <Button
                onClick={openStripeSession}
                colorScheme={"gray"}
                borderRadius={8}
                flexShrink={0}
              >
                {t("profile.menu.subscriptions")}
              </Button>
            ) : (
              <Button
                as={Link}
                to={"/pricing"}
                colorScheme={"gray"}
                borderRadius={8}
                flexShrink={0}
              >
                {t("profile.menu.subscriptions")}
              </Button>
            )}
            <Button
              mt={[4, 4, 8]}
              onClick={() => signOut(authIa)}
              bgColor={"gray.400"}
              color={"#fff"}
              borderRadius={8}
              alignSelf={"stretch"}
            >
              {t("profile.menu.logout")}
            </Button>
          </Stack>
          <HStack w={"100%"} justifyContent={"space-evenly"} hideFrom={"md"}>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                borderRadius={8}
              >
                {t("profile.menu.more")}
              </MenuButton>
              <MenuList>
                <MenuItem
                  as={Link}
                  to={"/profile"}
                  bgColor={
                    location.pathname === "/profile" ? "brand.500" : "white"
                  }
                  color={location.pathname === "/profile" ? "white" : "#000"}
                >
                  {t("profile.menu.recap")}
                </MenuItem>
                <MenuItem
                  as={Link}
                  to={"/profile/history"}
                  bgColor={
                    location.pathname === "/profile/history"
                      ? "brand.500"
                      : "white"
                  }
                  color={
                    location.pathname === "/profile/history" ? "white" : "#000"
                  }
                >
                  {t("profile.menu.history")}
                </MenuItem>
                <MenuItem
                  as={Link}
                  to={"/profile/summaries"}
                  bgColor={
                    location.pathname === "/profile/summaries"
                      ? "brand.500"
                      : "white"
                  }
                  color={
                    location.pathname === "/profile/summaries"
                      ? "white"
                      : "#000"
                  }
                >
                  {t("profile.menu.summaries")}
                </MenuItem>
                <MenuItem
                  as={Link}
                  to={"/profile/settings"}
                  bgColor={
                    location.pathname === "/profile/settings"
                      ? "brand.500"
                      : "white"
                  }
                  color={
                    location.pathname === "/profile/settings" ? "white" : "#000"
                  }
                >
                  {t("profile.menu.settings")}
                </MenuItem>
                {user?.currentPlan ? (
                  <MenuItem onClick={openStripeSession}>
                    {t("profile.menu.subscriptions")}
                  </MenuItem>
                ) : (
                  <MenuItem as={Link} to={"/pricing"}>
                    {t("profile.menu.subscriptions")}
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
            <Button
              mx={[4, 4, 8]}
              onClick={() => signOut(authIa)}
              bgColor={"gray.400"}
              color={"#fff"}
              borderRadius={8}
              maxW={"200px"}
              alignSelf={"center"}
            >
              {t("profile.menu.logout")}
            </Button>
          </HStack>
        </Box>

        <Outlet />
      </Stack>

      {/* <Grid
        templateColumns={["repeat(2, 1fr)", "repeat(6, 1fr)"]}
        templateRows={"auto 1fr"}
        gap={32}
      >
        <GridItem
          gridRow={"1 / 2"}
          gridColumn={"1 / -1"}
          bgImage={"/backgrounds/logo-brand.svg"}
          borderRadius={32}
        ></GridItem>
        <GridItem
          gridColumn={}
        ></GridItem>
        <GridItem>
          <Outlet />
        </GridItem>
      </Grid> */}
    </Container>
  );
};

export default Profile;
