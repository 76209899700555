import NavButton from "@/ui/Sidebar/NavButton";
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Icon,
  Image,
  Stack,
  Tag,
  Wrap,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useUserSession } from "@/hooks/useUserSession";
import { Link } from "react-router-dom";
import { MdCollectionsBookmark, MdHistory } from "react-icons/md";
import { FaFeatherAlt } from "react-icons/fa";

const SideBar = () => {
  const { t, i18n } = useTranslation();
  const { user } = useUserSession();
  return (
    <Box
      hideBelow={"lg"}
      width={"clamp(280px, 20%, 500px)"}
      background={"#fff"}
      borderRadius={"0px 60px 60px 0px"}
      padding={10}
      flexShrink={0}
      display={"flex"}
      flexDir={"column"}
      gap={10}
      minH={"100vh"}
      h={"100%"}
      justifyContent={"space-between"}
      position={"sticky"}
      top={0}
    >
      <HStack wrap={"wrap"}>
        <Image src="/images/logo-full.svg" alt="Paperdoc" width={200} />
        <ButtonGroup isAttached ml={"auto"}>
          <Button
            colorScheme={i18n.language == "fr" ? "brand" : "gray"}
            size={"sm"}
            onClick={() => i18n.changeLanguage("fr")}
          >
            FR
          </Button>
          <Button
            colorScheme={i18n.language == "en" ? "brand" : "gray"}
            size={"sm"}
            onClick={() => i18n.changeLanguage("en")}
          >
            EN
          </Button>
        </ButtonGroup>
      </HStack>
      <Stack direction={"column"} spacing={3} mb={"auto"}>
        <NavButton
          icon={<Image src="/images/search.svg" />}
          label={t("sidebar.search")}
          color="#D9D9D9"
          to="/"
        />
        <NavButton
          icon={<Image src="/images/dashboard.svg" />}
          label={t("sidebar.dashboard")}
          color="#00BCB0"
          to="/news"
        />

        {/*  <NavButton
          icon={<Image src="/images/community.svg" />}
          label={t("sidebar.community")}
          color="#17A4FF"
          to="/community"
        /> */}
        <NavButton
          icon={<Image src="/images/ia.svg" />}
          label={t("sidebar.ia")}
          color="#5A69F5"
          to="/ia"
        />
        <NavButton
          icon={<Icon as={MdCollectionsBookmark} color={"#fff"} w={6} h={6} />}
          label={t("profile.menu.collections")}
          color="#17A4FF"
          to="/collections"
        />

        {/*  <NavButton
          icon={<Image src="/backgrounds/folder.svg" w={6} h={6} />}
          label={t("sidebar.storage")}
          color="#00BCB0"
          to="/storage"
        /> */}

        <NavButton
          icon={<Icon as={MdHistory} color={"#fff"} w={6} h={6} />}
          label={t("profile.menu.history")}
          color="#D9D9D9"
          to="/profile/history"
        />
        <NavButton
          icon={<Image src="/images/toolbox.svg" />}
          label={t("sidebar.toolbox")}
          color="#1A1E13"
          to="/toolbox"
        />
        <NavButton
          icon={<FaFeatherAlt color={"#fff"} />}
          label={t("sidebar.synthia")}
          color="#B0E0E6"
          to="/synthia"
        />
      </Stack>
      <Stack direction={"column"} spacing={3}>
        <Box mt={"auto"}>
          {user?.currentPlan === "premium" && (
            <Wrap>
              <Tag
                bgColor={"brand.500"}
                color={"#FFF"}
                as={Link}
                to={"/profile"}
                alignSelf={"flex-start"}
              >
                {t("sidebar.premium")}
              </Tag>
              <Tag bgColor={"brand.500"} color={"#FFF"}>
                {t("sidebar.paperCoinsUnlimited")}
              </Tag>
            </Wrap>
          )}
          {user?.currentPlan === "silver" && (
            <Wrap>
              <Tag as={Link} to={"/pricing"} alignSelf={"flex-start"}>
                {t("sidebar.silver")}
              </Tag>
              <Tag bgColor={"brand.500"} color={"#FFF"}>
                {t("sidebar.paperCoins", { coins: user?.paperCoins || 0 })}
              </Tag>
            </Wrap>
          )}

          {!user?.currentPlan && (
            <Wrap>
              <Button as={Link} to={"/pricing"} colorScheme="brand">
                {t("sidebar.upgrade")}
              </Button>
              <Tag bgColor={"brand.500"} color={"#FFF"}>
                {t("sidebar.paperCoins", { coins: user?.paperCoins || 0 })}
              </Tag>
            </Wrap>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default SideBar;
