import { create } from "zustand";

type SearchFiltersData = {
  year: {
    min: string;
    max: string;
  } | null;
  minCitations: number | undefined;
  authors: string[];
  isOpenAccess: boolean;
  fieldsOfStudy: string[];
  publicationTypes: string[];
  publicationVenues: {
    value: string;
    label?: string;
  }[];
};

type SearchFiltersActions = {
  setYear: (min: string, max: string) => void;
  setMinCitations: (minCitations: number) => void;
  setAuthors: (authors: string[]) => void;
  setIsOpenAccess: (isOpenAccess: boolean) => void;
  setFieldsOfStudy: (fieldsOfStudy: string[]) => void;
  setPublicationTypes: (publicationTypes: string[]) => void;
  setPublicationVenues: (
    publicationVenues: SearchFiltersData["publicationVenues"]
  ) => void;
  reset: () => void;
};

const initialState: SearchFiltersData = {
  year: null,
  minCitations: 2,

  isOpenAccess: false,
  fieldsOfStudy: [],
  publicationTypes: [],
  authors: [],
  publicationVenues: [],
};

const useSearchFilters = create<SearchFiltersData & SearchFiltersActions>(
  (set) => ({
    ...initialState,
    setYear: (min, max) => set({ year: { min, max } }),
    setMinCitations: (minCitations) => set({ minCitations }),
    setAuthors: (authors) => set({ authors }),
    setIsOpenAccess: (isOpenAccess) => set({ isOpenAccess }),
    setFieldsOfStudy: (fieldsOfStudy) => set({ fieldsOfStudy }),
    setPublicationTypes: (publicationTypes) => set({ publicationTypes }),
    setPublicationVenues: (publicationVenues) => set({ publicationVenues }),
    reset: () => set(initialState),
  })
);

export default useSearchFilters;
