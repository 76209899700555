import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  HStack,
  ListItem,
  SkeletonText,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import parseText from "./ArchieQ/TextParser";
import useArchieData from "@/hooks/useArchieData";
import { useCallback, useEffect, useRef, useState } from "react";

import { doc, onSnapshot } from "firebase/firestore";
import { dbIa } from "@/lib/firebase";
import { runArchieSumm } from "@/lib/cloudFunctions/ArchieSumm";

import CopyContent from "./CopyContent";

import { useTranslation } from "react-i18next";
const ArchieSumm = () => {
  const { archieSUMM, setArchieData, token, setRefs, refs } = useArchieData(
    (state) => state
  );

  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const fetchArchieSumm = useCallback(async () => {
    setLoading(true);
    await runArchieSumm({
      token,
    }).catch(() => {
      setError(true);
    });

    setLoading(false);
  }, [token]);

  useEffect(() => {
    if (token && isOpen) {
      const unsub = onSnapshot(doc(dbIa, "archie_summ", token), (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setRefs(new Set(data.papers));
          setArchieData({
            archieSUMM: {
              papers: data.papers,
              summary: data.summary,
            },
          });
          setLoading(false);
        } else {
          fetchArchieSumm();
        }
      });
      return () => unsub();
    }
  }, [setRefs, setArchieData, fetchArchieSumm, token, isOpen]);

  let sourceCount = 1;

  if (!token) return null;

  return (
    <Accordion
      allowToggle
      onChange={() => setIsOpen(!isOpen)}
      index={isOpen ? 0 : -1}
      mt={4}
      w={"100%"}
    >
      <AccordionItem>
        <AccordionButton
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={4}
        >
          <Text
            textAlign={"left"}
            sx={{
              textWrap: "balance",
            }}
            fontWeight={"bold"}
          >
            {t("archie.summary")}
          </Text>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          {error ? (
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              gap={4}
              marginY={"auto"}
              minW={"400px"}
            >
              <Text> {t("archie.error.title")}</Text>
              <UnorderedList>
                <ListItem>{t("archie.error.0")}</ListItem>
                <ListItem>{t("archie.error.2")}</ListItem>
                <ListItem>{t("archie.error.3")}</ListItem>
              </UnorderedList>

              <Text>{t("archie.error.final")}</Text>
            </Box>
          ) : loading ? (
            <SkeletonText w={"100%"} noOfLines={2} spacing={4} />
          ) : (
            <Box>
              <HStack alignItems={"flex-start"}>
                <Box ref={contentRef}>
                  {archieSUMM &&
                    archieSUMM.summary.sections.map((section, index) => {
                      const { parts, currentSource } = parseText(
                        section.content,

                        sourceCount
                      );
                      sourceCount = currentSource;

                      return (
                        <div key={index}>
                          <Heading as={"h4"} size={"md"} my={4}>
                            {section.section_title}
                          </Heading>
                          {section.content ? (
                            <Text>{parts.map((e) => e)}</Text>
                          ) : (
                            <>
                              {section.sub_sections.map(
                                (sub_section, sub_index) => {
                                  const { parts, currentSource } = parseText(
                                    sub_section.content,

                                    sourceCount
                                  );
                                  sourceCount = currentSource;
                                  return (
                                    <div key={sub_index}>
                                      <Heading as={"h5"} size={"sm"} my={3}>
                                        {sub_section.sub_section_title}
                                      </Heading>
                                      <Text>{parts.map((e) => e)}</Text>
                                    </div>
                                  );
                                }
                              )}
                            </>
                          )}
                        </div>
                      );
                    })}
                </Box>
                <CopyContent contentRef={contentRef} refs={[...refs]} />{" "}
              </HStack>
            </Box>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default ArchieSumm;
