import { checkSessionToken } from "@/lib/cloudFunctions/session/CheckSessionToken";
import { setSessionToken } from "@/lib/cloudFunctions/session/SetSessionToken";
import { authIa, dbIa } from "@/lib/firebase";
import { User } from "@/lib/types";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { nanoid } from "nanoid";

import { createContext, useContext, useEffect, useRef, useState } from "react";

type UserSessionContextType = {
  user: User | null;
  isReady: boolean;
  setHasSignedIn: React.Dispatch<React.SetStateAction<boolean>>;
};

const UserSessionContext = createContext<UserSessionContextType>({
  user: null,
  isReady: false,
  setHasSignedIn: () => {},
});

const UserSessionProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [hasSignedIn, setHasSignedIn] = useState(false);

  const unsubscribe = useRef<() => void>(() => {});
  useEffect(() => {
    const unsub = onAuthStateChanged(authIa, async (user) => {
      if (user?.email) {
        //Make sure the user is logged in on one web session only
        const _lastLoginAt = new Date(
          user.metadata.lastSignInTime || (user.metadata.creationTime as string)
        );

        const currentTimestamp = new Date();
        const diff = currentTimestamp.getTime() - _lastLoginAt.getTime();

        const token = localStorage.getItem("sessionToken") || nanoid();

        //check if last login was more than 5 seconds ago
        if (diff > 5000 && !hasSignedIn) {
          const res = await checkSessionToken({ token: token });

          if (!res.data.success) {
            //User is logged in on another web session
            signOut(authIa);
            return;
          }
        } else {
          //Set current session token
          await setSessionToken({ token: token });
        }

        localStorage.setItem("sessionToken", token);

        unsubscribe.current = onSnapshot(
          doc(dbIa, "users", user.uid),
          (_doc) => {
            setUser({
              ..._doc.data(),
              id: _doc.id,
              ref: _doc.ref,
              providerData: user.providerData,
            } as User);
            setIsReady(true);
          }
        );
      } else {
        unsubscribe.current();
        setUser(null);
        setIsReady(true);
      }
    });

    return () => {
      unsub();
      unsubscribe.current();
    };
  }, [hasSignedIn]);
  return (
    <UserSessionContext.Provider
      value={{ user: user, isReady: isReady, setHasSignedIn }}
    >
      {children}
    </UserSessionContext.Provider>
  );
};

const useUserSession = (): UserSessionContextType =>
  useContext(UserSessionContext);

export { useUserSession };
export default UserSessionProvider;
