import { authIa, dbIa } from "@/lib/firebase";
import { Button, Image, Stack, Text } from "@chakra-ui/react";
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { useTranslation } from "react-i18next";

type AuthProvidersProps = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

const AuthProviders = (props: AuthProvidersProps) => {
  const { loading, setLoading } = props;

  const { t } = useTranslation();

  const googleProvider = new GoogleAuthProvider();
  const appleProvider = new OAuthProvider("apple.com");

  const checkIfUserExists = async (email: string) => {
    const usersRef = collection(dbIa, "users");
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty;
  };

  const onSubmit = async (type: "GOOGLE" | "APPLE") => {
    try {
      setLoading(true);
      const data = await signInWithPopup(
        authIa,
        type === "GOOGLE" ? googleProvider : appleProvider
      );

      const userExist = await checkIfUserExists(data.user.email || "");
      if (!userExist) {
        const splitName = (displayName: string) => {
          const lastSpaceIndex = displayName.lastIndexOf(" ");
          if (lastSpaceIndex === -1) {
            return { firstName: displayName, lastName: "" };
          }
          return {
            firstName: displayName.substring(0, lastSpaceIndex).trim(),
            lastName: displayName.substring(lastSpaceIndex + 1).trim(),
          };
        };

        const { firstName, lastName } = splitName(data.user.displayName || "");

        await setDoc(
          doc(dbIa, "users", data.user.uid),
          {
            email: data.user.email,
            firstName,
            lastName,
            createdAt: new Date(),
            registrationCompleted: false,
            cgv: true,
          },
          { merge: true }
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack alignSelf={"center"} spacing={5} width={"100%"}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <span style={{ height: 1, width: "100%", backgroundColor: "#fff" }} />
        <Text color={"#fff"} textAlign={"center"} margin="0 15px">
          {t("auth.providers.or")}
        </Text>
        <span style={{ height: 1, width: "100%", backgroundColor: "#fff" }} />
      </div>
      <Button
        backgroundColor="#fff"
        _hover={{ backgroundColor: "rgba(0, 188, 175)", color: "#fff" }}
        type="submit"
        isLoading={loading}
        onClick={() => onSubmit("GOOGLE")}
        gap={2}
      >
        <Image src="/images/google-logo.png" height={5} />
        {t("auth.providers.google")}
      </Button>
      <Button
        backgroundColor="#fff"
        _hover={{ backgroundColor: "rgba(0, 188, 175)", color: "#fff" }}
        type="submit"
        isLoading={loading}
        onClick={() => onSubmit("APPLE")}
        gap={2}
      >
        <Image src="/images/apple-logo.png" height={5} />
        <Text textAlign={"center"} marginTop={1}>
          {t("auth.providers.apple")}
        </Text>
      </Button>
    </Stack>
  );
};

export default AuthProviders;
