import Archie from "@/components/search/Archie";
import useArchieData from "@/hooks/useArchieData";
import { Box, Skeleton } from "@chakra-ui/react";

const SearchLoading = ({ count = 10, hideArchie = false }) => {
  const { displayed } = useArchieData();
  return (
    <Box w={"100%"} gap={4} p={2} display={"flex"} flexDirection={"column"}>
      {(displayed.insights || displayed.summary) && !hideArchie && <Archie />}

      {Array.from({ length: count < 10 ? count : 10 }).map((_, index) => (
        <Skeleton
          w={"100%"}
          h={"100px"}
          key={index}
          bgColor={"#fff"}
          borderRadius={28}
          p={2}
        />
      ))}
    </Box>
  );
};

export default SearchLoading;
