import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Box,
  HStack,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import parseText from "./ArchieQ/TextParser";
import useArchieData from "@/hooks/useArchieData";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { dbIa } from "@/lib/firebase";

import ArchieQAnswerContainer from "./ArchieQ/ArchieQAnswerContainer";

const ArchieQ = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const [openedIndexes, setOpenedIndexes] = useState<number[]>([0]);

  const {
    archieQ,

    setArchieData,
    setRefs: setArchieRefs,
    token,
  } = useArchieData((state) => state);

  useEffect(() => {
    if (token) {
      const unsub = onSnapshot(doc(dbIa, "archie_q", token), (doc) => {
        if (doc.exists()) {
          const data = doc.data();

          let sourceCount = 1;
          const answers = data.answers.map((answer: string | null) => {
            if (answer === null) {
              return null;
            }
            const { parts, currentSource } = parseText(answer, sourceCount);

            sourceCount = currentSource;
            return parts;
          });
          setArchieData({
            archieQ: {
              answers: answers,
              papers: data.papers,
              questions: data.questions,
            },
          });

          setArchieRefs(new Set(data.papers));
          setLoading(false);
        }
      });
      return () => unsub();
    }
  }, [token, setArchieData, setArchieRefs]);

  if (loading) return <SkeletonText w={"100%"} noOfLines={2} spacing={4} />;

  if (!archieQ) return null;

  return (
    <Box>
      <HStack gap={4} flexWrap={"wrap"} mt={4}></HStack>

      <Accordion
        defaultIndex={[0]}
        allowMultiple
        onChange={(index) => {
          const indexes = index as number[];
          setOpenedIndexes(indexes);
        }}
        index={openedIndexes}
      >
        <AccordionItem>
          <AccordionButton
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={4}
          >
            <Text
              textAlign={"left"}
              sx={{
                textWrap: "balance",
              }}
              fontWeight={"bold"}
            >
              {archieQ.questions[0].question ||
                (archieQ.questions[0] as unknown as string)}
              {/* archieQ.questions[0] as unknown as string is used for old saved reseaches  */}
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <ArchieQAnswerContainer
            index={0}
            isOpen={openedIndexes.includes(0)}
            answer={archieQ.answers[0]}
            question={
              archieQ.questions[0].question ||
              (archieQ.questions[0] as unknown as string)
            }
            token={token}
          />
        </AccordionItem>
        <Text fontWeight={"bold"} as={"h3"} size={"md"} my={4}>
          {t("archie.additional_questions")}
        </Text>
        {archieQ.questions.slice(1).map((question, index) => (
          <AccordionItem key={index + 1}>
            <AccordionButton
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={4}
            >
              <Text
                textAlign={"left"}
                sx={{
                  textWrap: "balance",
                }}
                fontWeight={"bold"}
              >
                {question.question || (question as unknown as string)}
                {/* question as unknown as string is used for old saved reseaches  */}
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <ArchieQAnswerContainer
              index={index + 1}
              isOpen={openedIndexes.includes(index + 1)}
              answer={archieQ.answers[index + 1]}
              question={question.question}
              token={token}
            />
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default ArchieQ;
