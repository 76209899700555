import { functions } from "@/lib/firebase";
import { httpsCallable } from "firebase/functions";

type ArchieRunRequest = {
  token: string;
};

type ArchieRunResponse = {
  status: string;
};

/**
 * Start an archie run
 * Add a listener to the archie run document to get the results
 * @param token - The ai_searches firebase document id
 * @returns The status of the archie run
 */
const runArchie = httpsCallable<ArchieRunRequest, ArchieRunResponse>(
  functions,
  "start_archie_run"
);

export { runArchie };
