import { useUserSession } from "@/hooks/useUserSession";
import { functions } from "@/lib/firebase";

import AuthContainer from "@/ui/Auth/AuthContainer";
import {
  Box,
  Button,
  Heading,
  Input,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { httpsCallable } from "firebase/functions";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdFileUpload } from "react-icons/md";
import { Link } from "react-router-dom";

type FormData = {
  email: string;
  name: string;
  message: string;
  file?: FileList;
};

const Contact = () => {
  const { t } = useTranslation();
  const { user } = useUserSession();

  const toast = useToast();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      email: user?.email || "",
      name: user ? `${user.firstName} ${user.lastName}` : "",
    },
  });
  const fileList = watch("file");
  const getBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      if (file.size > 8 * 1024 * 1024) {
        reject("File is too big");
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      let base64: string | null = null;

      if (data.file?.length) {
        try {
          base64 = (await getBase64(data.file[0])) as string;
        } catch (error) {
          toast({
            title: t("contact.fileSizeError"),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      }
      const sendEmail = httpsCallable(functions, "sendContactEmail");

      await sendEmail({
        ...data,
        file: base64,
      });

      toast({
        title: t("contact.success"),
        description: t("contact.successDescription"),
        status: "success",
        position: "top",
        duration: 5000,
        isClosable: true,
      });
      reset();
    } catch (error) {
      toast({
        title: t("contact.error"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  });

  return (
    <AuthContainer pt={8}>
      <Heading mb={8} color={"#fff"}>
        {t("contact.title")}
      </Heading>

      <Box
        as="form"
        display={"flex"}
        flexDir={"column"}
        gap={8}
        w={"clamp(280px, 70%, 500px)"}
        onSubmit={onSubmit}
      >
        <Input
          variant={"auth"}
          placeholder={t("auth.login.email")}
          {...register("email")}
          required
        />
        {errors.email && <Box color={"red"}>{errors.email.message}</Box>}
        <Input
          variant={"auth"}
          placeholder={t("contact.name")}
          {...register("name")}
          required
        />
        {errors.name && <Box color={"red"}>{errors.name.message}</Box>}

        <Textarea
          variant={"auth"}
          placeholder={t("contact.message")}
          {...register("message")}
          required
        />
        {errors.message && <Box color={"red"}>{errors.message.message}</Box>}

        <VStack spacing={4} w={"100%"}>
          <Button
            as={"label"}
            htmlFor="file"
            colorScheme="brand"
            cursor={"pointer"}
          >
            {fileList?.length
              ? t("contact.updateFile")
              : t("contact.attachFile")}
            <MdFileUpload />
          </Button>
          <input
            hidden
            id="file"
            type="file"
            accept="application/pdf,image/*"
            {...register("file")}
          />
          <Text color={"#fff"}>{t("contact.fileSize")} </Text>
          {fileList?.length && (
            <Text color={"#fff"}>
              {t("contact.fileAttached")} : {fileList[0].name}
            </Text>
          )}
        </VStack>

        <Button colorScheme="brand" type="submit" isLoading={isSubmitting}>
          {t("contact.submit")}
        </Button>

        <Text
          color={"#fff"}
          as={Link}
          to={"/"}
          textAlign={"center"}
          textDecoration={"underline"}
        >
          {t("contact.return")}
        </Text>
      </Box>
    </AuthContainer>
  );
};

export default Contact;
